<template>
  <div class="specimen-data-container">
    <!-- 新增和编辑标本按钮 -->
    <el-button type="primary" style="margin-bottom:15px;" @click="openDialog">新增</el-button>
    <el-button type="primary" style="margin-bottom:15px;" @click="toggleEditMode" v-if="!isAuth('2')">{{ editMode ? '取消编辑' : '编辑标本' }}</el-button>
    <el-table v-loading="dataListLoading" :data="tableData" border style="width: 100%;" height="90%" :span-method="objectSpanMethod" :header-cell-style="{ textAlign: 'center', verticalAlign: 'middle' }" :cell-style="{ textAlign: 'center', verticalAlign: 'middle' }">
      <!-- 顶部标题 -->
      <el-table-column :resizable="false" width="50">
        <template slot-scope="scope">
          <div style="cursor:default;">{{ scope.row.header1 }}</div>
        </template>
      </el-table-column>
      <el-table-column :resizable="false" width="80">
        <template slot-scope="scope">
          <div style="cursor:default;">{{ scope.row.header2 }}</div>
        </template>
      </el-table-column>
      <el-table-column :resizable="false" width="80">
        <template slot-scope="scope">
          <div style="cursor:default;">{{ scope.row.header3 }}</div>
        </template>
      </el-table-column>
      <el-table-column v-for="(label, index) in headers" :key="index" :resizable="false" :label="label" :prop="label" width="200">
        <template slot-scope="scope">
          <div @dblclick="handleCellDblClick(scope.row[label])" style="cursor:pointer;" @mouseover="showTooltip">
            <el-tooltip v-if="(scope.$index + 1) % 8 === 0" :content="scope.row[label].value" placement="top" dark="light">
              <div class="elltip" v-if="editMode" @click="editCell(scope.row[label])">{{ scope.row[label].value }}</div>
              <div class="elltip" v-else>{{ scope.row[label].value }}</div>
            </el-tooltip>
            <div v-else>
              <div class="elltip" v-if="editMode" @click="editCell(scope.row[label])">{{ scope.row[label].value }}</div>
              <div class="elltip" v-else>{{ scope.row[label].value }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增/编辑表单对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" center>
      <el-form :model="form" ref="form" label-width="100px" v-loading="editFormLoading" :rules="rules">
        <el-form-item label="所在行数" prop="row">
          <el-select v-model="form.row" placeholder="请选择行数" :disabled="editMode">
            <el-option v-for="row in rows" :key="row" :label="row" :value="row" :disabled="isCoordinateDisabled(row, form.line)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在列数" prop="line">
          <el-select v-model="form.line" placeholder="请选择列数" :disabled="editMode">
            <el-option v-for="line in lines" :key="line" :label="line" :value="line" :disabled="isCoordinateDisabled(form.row, line)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="住院号" prop="inpatientNumber">
          <el-input v-model="form.inpatientNumber"></el-input>
        </el-form-item>
        <el-form-item label="入院日期" prop="admissionDate">
          <el-date-picker v-model="form.admissionDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="取样日期" prop="samplingDate">
          <el-date-picker v-model="form.samplingDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="使用人" prop="operator">
          <el-input v-model="form.operator"></el-input>
        </el-form-item>
        <el-form-item label="用途" prop="purpose">
          <el-input v-model="form.purpose"></el-input>
        </el-form-item>
        <el-form-item label="使用日期" prop="useDate">
          <el-date-picker v-model="form.useDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="human">人</el-radio>
            <el-radio label="mouse">老鼠</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="其他备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogVisible = false" :disabled="editFormLoading">取消</el-button>
          <el-button type="primary" @click="onSubmit" :disabled="editFormLoading">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getBoxListByRefrigeratorId, getSpecimenListByBoxId, addOrUpdateBox } from '@/api/specimen'
export default {
  data() {
    return {
      headers: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], // 简化表头
      tableData: this.generateTableData(), // 初始的空表格数据
      specimenBoxData: [], // 服务器返回的标本盒数据
      dialogVisible: false, // 控制对话框显示
      dialogTitle: '新增标本', // 对话框标题
      form: {
        id: null,
        boxId: '',
        refrigeratorId: this.$route.query.rId,
        row: '',
        line: '',
        name: '',
        inpatientNumber: '',
        admissionDate: '',
        samplingDate: '',
        operator: '',
        purpose: '',
        useDate: '',
        type: "human", //类型(人:human  老鼠:mouse)
        remark: ''
      },
      rows: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      lines: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
      editMode: false, // 编辑模式开关
      dataListLoading: false,// 数据加载状态
      editFormLoading: false,//编辑表单加载状态
      disabledCoordinates: [], // 禁用的坐标点
      rules: {
        row: [{ required: true, message: '请选择所在行数', trigger: 'change' }],
        line: [{ required: true, message: '请选择所在列数', trigger: 'change' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }]
      }
    };
  },
  created() {
    this.getSpecimenBoxData(); // 在组件创建时获取数据
  },
  methods: {
    showTooltip(event) {
      event.target.title = "双击查看标本详情";
    },
    // 打开对话框
    openDialog() {
      this.dialogTitle = '新增标本';
      this.dialogVisible = true;
      this.editMode = false;
      this.resetForm();
      this.calculateDisabledCoordinates();
    },
    // 切换编辑模式
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.$message({
          message: '编辑模式下，点击单元格可编辑标本信息',
          type: 'success'
        });
      }
    },
    // 生成基础表格数据
    generateTableData() {
      const data = [];
      const sampleInfoFields = ['姓名', '住院号', '入院日期', '取样日期'];
      const usageInfoFields = ['使用人', '使用用途', '使用日期', '其他备注'];

      for (let i = 1; i <= 9; i++) {
        // 添加标本信息
        data.push(...this.createRows(i, '标本信息', sampleInfoFields));
        // 添加使用信息
        data.push(...this.createRows(i, '使用信息', usageInfoFields));
      }
      return data;
    },
    // 根据行和列生成初始的表格数据
    createRows(index, header2, fields) {
      return fields.map((field, idx) => {
        return {
          header1: idx === 0 ? `${index}` : '', // 只有第一行显示 index
          header2: idx === 0 ? header2 : '',   // 只有第一行显示 header2
          header3: field,
          A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: '', I: '', // 初始化为空
          id: null, boxId: '', refrigeratorId: '' // 添加 id, boxId, refrigeratorId 字段
        };
      });
    },
    // 获取标本盒数据
    getSpecimenBoxData() {
      this.dataListLoading = true;
      getBoxListByRefrigeratorId(this.$route.query.rId).then(res => {
        this.specimenBoxData = res.data;
        this.populateTableData(); // 填充表格
        this.dataListLoading = false;
      });
    },
    // 将标本盒数据填入表格
    populateTableData() {
      this.specimenBoxData.forEach(box => {
        const row = parseInt(box.row); // 获取所在行
        const line = box.line;         // 获取所在列 ('A'-'I')

        // 找到对应行的标本信息和使用信息部分
        const sampleInfoIndex = (row - 1) * 8;
        const usageInfoIndex = sampleInfoIndex + 4;

        // 为每个字段创建一个对象，包含 `id`、`boxId` 和 `refrigeratorId`
        this.tableData[sampleInfoIndex][line] = {
          value: box.name,
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[sampleInfoIndex + 1][line] = {
          value: box.inpatientNumber,
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[sampleInfoIndex + 2][line] = {
          value: this.formatDate(box.admissionDate),
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[sampleInfoIndex + 3][line] = {
          value: this.formatDate(box.samplingDate),
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[usageInfoIndex][line] = {
          value: box.operator,
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[usageInfoIndex + 1][line] = {
          value: box.purpose,
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[usageInfoIndex + 2][line] = {
          value: this.formatDate(box.useDate),
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
        this.tableData[usageInfoIndex + 3][line] = {
          value: box.remark,
          id: box.id,
          boxId: box.boxId,
          refrigeratorId: box.refrigeratorId,
          row: box.row,
          line: box.line,
          name: box.name,
          inpatientNumber: box.inpatientNumber,
          admissionDate: box.admissionDate,
          samplingDate: box.samplingDate,
          operator: box.operator,
          purpose: box.purpose,
          useDate: box.useDate,
          remark: box.remark
        };
      });
    },
    // 计算禁用的坐标点
    calculateDisabledCoordinates() {
      this.disabledCoordinates = [];

      this.specimenBoxData.forEach(box => {
        this.disabledCoordinates.push(`${box.row}-${box.line}`);
      });
    },
    // 检查坐标点是否被禁用
    isCoordinateDisabled(row, line) {
      return this.disabledCoordinates.includes(`${row}-${line}`);
    },
    // 合并单元格
    objectSpanMethod({ rowIndex, columnIndex }) {
      // 合并第一列的单元格
      if (columnIndex === 0) return this.mergeCells(rowIndex, 8);
      // 合并第二列的单元格
      if (columnIndex === 1) {
        return rowIndex % 8 === 0 ? { rowspan: 4, colspan: 1 } : (rowIndex % 8 === 4 ? { rowspan: 3, colspan: 1 } : { rowspan: 0, colspan: 0 });
      }
      // 合并第三列的单元格
      if (columnIndex === 2 && rowIndex % 8 === 7) {
        return { rowspan: 1, colspan: 2 };
      }
    },
    mergeCells(rowIndex, size) {
      return rowIndex % size === 0 ? { rowspan: size, colspan: 1 } : { rowspan: 0, colspan: 0 };
    },
    // 格式化日期
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);

      // 获取年月日等信息
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
      const day = String(date.getDate()).padStart(2, '0');

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 拼接成所需格式
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    // 双击单元格事件
    handleCellDblClick(row) {
      this.$router.push(`/specimen-detail?bId=${row.boxId}`);
    },
    // 单击单元格事件
    editCell(row) {
      console.log('编辑标本:', row);

      if (this.editMode) {
        this.dialogTitle = '编辑标本';
        this.dialogVisible = true;
        this.editFormLoading = true;
        this.resetForm();
        getSpecimenListByBoxId(row.boxId).then(res => {
          let boxData = res.data.humanVO === null ? res.data.mouseVO : res.data.humanVO
          console.log('标本数据:', boxData);
          this.form = {
            id: row.id,
            boxId: row.boxId,
            refrigeratorId: row.refrigeratorId,
            row: row.row,
            line: row.line,
            name: row.name,
            inpatientNumber: row.inpatientNumber,
            admissionDate: row.admissionDate,
            samplingDate: row.samplingDate,
            operator: row.operator,
            purpose: row.purpose,
            useDate: row.useDate,
            type: boxData.type,
            remark: row.remark
          }
          this.editFormLoading = false;
        });
      }
    },
    // 提交表单
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.editFormLoading = true;
          this.form.admissionDate = this.formatDate(this.form.admissionDate);
          this.form.samplingDate = this.formatDate(this.form.samplingDate);
          this.form.useDate = this.formatDate(this.form.useDate);
          addOrUpdateBox(this.form).then(() => {
            this.getSpecimenBoxData(); // 重新获取数据
            this.dialogVisible = false; // 关闭对话框
            this.editFormLoading = false;
            this.$message.success('提交成功');
          }).catch(error => {
            console.error('提交失败', error);
            this.editFormLoading = false;
          })
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    // 重置表单
    onReset() {
      this.$refs.form.resetFields();
    },
    // 重置表单数据
    resetForm() {
      this.form = {
        id: null,
        boxId: '',
        refrigeratorId: this.$route.query.rId,
        row: '',
        line: '',
        name: '',
        inpatientNumber: '',
        admissionDate: '',
        samplingDate: '',
        operator: '',
        purpose: '',
        useDate: '',
        type: "human",
        remark: ''
      };
    }
  },
};
</script>

<style scoped>
.specimen-data-container {
  height: 100%;
}

::v-deep .el-table--border .el-table__cell:first-child .cell {
  padding: 0 0;
}
::v-deep .el-table .el-table__cell {
  padding: 0 0;
  height: 40px;
}

::v-deep .el-table .cell {
  padding: 10px;
}

.elltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>